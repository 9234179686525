import React from 'react'
import { Link } from 'react-router-dom'
import modelling from '../img/3dservie/service2.png';
import scanning from '../img/3dservie/service1.png';
import printing from '../img/3dservie/service3.jpg';


export default function ServiceNav() {
    function slideTop(){
        window.scrollTo({
            top: 100,
            left: 0,
            behavior: 'smooth'
          });
    }
  return (
    <>
    Навигация
    <div className='service_nav'>
        <div className='service_nav_item' onClick={slideTop}>
            <Link to='/modeling'>
                <img className='modeling_img' src={modelling} alt='3D моделирование навигация'/>
                <div className='servcice_nav_item_button'>3D Моделирование</div>
                <div className='servcice_nav_item_text'>Разработка 3D модели различной сложности.</div>
            </Link>
        </div>
        <div className='service_nav_item' onClick={slideTop}>
            <Link to='/scanning'>
                <img className='scanning_img' src={scanning} alt='3D сканирование навигация'/>
                <div className='servcice_nav_item_button'>3D Сканирование</div>
                <div className='servcice_nav_item_text'>Сканирование любого объекта, для печати и обратного проэктирования.</div>
            </Link>
        </div>
        <div className='service_nav_item' onClick={slideTop}>
            <Link to='/printing'>
                <img className='printing_img' src={printing} alt='3D печать навигация'/>
                <div className='servcice_nav_item_button'>3D Печать</div>
                <div className='servcice_nav_item_text'>Быстрая и качестванная печать моделей из различных материалов.</div>
            </Link></div>
    </div>
    </>
  )
}
