import {React, useRef, useEffect} from 'react'
import { register } from 'swiper/element/bundle'
import printers from '../img/SliderImg/printers.png'
import modeling from '../img/SliderImg/modeling.png'
import service from '../img/SliderImg/service.png'
import spareParts from '../img/SliderImg/spareParts.png'
import toys from '../img/SliderImg/toys.png'
import filament from '../img/SliderImg/filament.jpg'
import { Link } from 'react-router-dom'


register();

export default function SwiperImg() {
  const swiperRef = useRef(null);

  useEffect(() => {
    // Register Swiper web component
    register();

    // Object with parameters
    const params = {
      slidesPerView: 1,
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);

  return (
    <swiper-container init="false" ref={swiperRef}>
        <swiper-slide><Link to='/3dservice'><img src={modeling} alt='Слайдер фото, ссылка на 3D услуги'></img></Link></swiper-slide>
        <swiper-slide><Link to='/spareParts'><img src={spareParts} alt='Ссылка на каталог запчастей'></img></Link></swiper-slide>
        <swiper-slide><Link to='/printers'><img src={printers} alt='ССылка на каталог 3D принтеров'></img></Link></swiper-slide>
        <swiper-slide><Link to='/service'><img src={service} alt='ССылка на ремонт и сервис 3D принтеров'></img></Link></swiper-slide>
        <swiper-slide><Link to='/toys'><img src={toys} alt='Ссылка на каталог игрушек и товаров для дома'></img></Link></swiper-slide>
        <swiper-slide><Link to='/filament'><img src={filament} alt='Ссылка на каталог пластика для 3D принетров'></img></Link></swiper-slide>
    </swiper-container>
  )
}
