import React from 'react'
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterPrintArea() {
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_printArea'>
      <div className='printArea_title filter_second_title' onClick={()=>setHidden(!hidden)}>Область печати <FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
      {!hidden && (<>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('in[printAreaVariations][]=SmallArea&'))} />
          <span>До 200мм</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('in[printAreaVariations][]=MediumArea&'))} />
          <span>От 200 до 300мм</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('in[printAreaVariations][]=HighArea&'))} />
          <span>От 300мм</span>
        </label>
      </>)}
</div>
  )
}
