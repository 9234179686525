import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import ServiceNav from '../ServiceNav';
import SocialBlock from '../SocialBlock';
import YandexMap from '../YandexMap';
import GetCall from '../GetCall';
import headImg from '../../img/3dservie/printing1.jpg'
import EquipmentService from '../EquipmentService';
import SliderService from '../SliderService';
import { Helmet } from 'react-helmet-async';

export default function ServicePrintingPage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>3D печать на заказ Санкт-Петербург. Печать пластиком и смолой. Художественная и техническая печать. Быстрая и качественная печать. Работа с Юр. Лицами.</title>
        <meta  name='description' content='3D печать на заказ. Оптовое производство любых деталей и игрушек. PLA, PetG, ABS, Nylon.'/>
        <meta name="keywords" content="3д печать, моделирование, опт, качество, creality, FDM" />
      </Helmet>
        <Header />
        <Nav />
        <main className='three_service_main'>
          <div className='modeling_head'>
            <div className='modeling_head_text'>
              <div className='modeling_head_text_title'>3D печать на заказ</div>
              <h4 className='modeling_head_text_h2'>Какой печатью мы занимаемся?</h4>
              <p className='modeling_head_text_p'>Мы специализируемся на FDM печати, то есть созданием объектов 
                при помощи послойного нанесения расплавленного материала. </p>

              <h4 className='modeling_head_text_h2'>Преимущества работы с нами:</h4>
              <p className='modeling_head_text_p'> 
                •  Мы работаем с различными видами пластика, такими как: PLA, PETG, TPU, ABS и NYLON.<br/>
                • Работаем с AMS системой, что позволяет использовать в печати до 4 цветов одновременно.<br/>
                • Заранее обговариваем сроки и стоимость выполнения проектов.<br/>
                • Готовы обеспечить оптовое производство ваших моделей.</p>

              <h4 className='modeling_head_text_h2'>Как заказать 3D печать:</h4>
              <p className='modeling_head_text_p'>
                1) Подготовить 3D модель в одном из распространенных форматов (STL, OBJ и др.). Если у вас нет модели, то мы можем подключить
                наших специалистов по 3D моделированию (подробности во вкладке моделирование).<br/>
                2) Определить нужное количество моделей для печати.<br/>
                3) Связаться с нами в удобном для вас мессенджере (WhatsApp, Telegram) для уточнения стоимости и сроков работы.</p>
              <h4 className='modeling_head_text_h2'>Обратите внимание, что минимальная стоимость печати от 3 000 рублей.</h4>
              <GetCall />          
            </div>
            <div className='modeling_head_img'><img src={headImg} alt='3D печать для турнира Bereddy 2 champ Dota 2, Cs 1.6 Печать кубков' />
              <div className='modeling_head_img_alt'>Реализованный проект для чемпионата BeReddy 2 Champ</div>
            </div>
          </div>
          <p className='service_galary_title'>Галерея наших работ</p> 
          <SliderService />
          <EquipmentService />
          <ServiceNav />
          <SocialBlock />
        </main>
        <YandexMap />
        <Footer />
      </React.Fragment>
      )
    }