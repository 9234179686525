import React from 'react'
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';

export default function FilterFilamentType() {
    const [hidden, setHidden] = useState(false);
    const dispatch = useDispatch();
  return (
    <div className='filter_sidebar filter_filamentType'>
    <div className='printArea_title filter_second_title' onClick={()=>setHidden(!hidden)}>Тип пластика<FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
    {!hidden && (<>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=PLA&'))} />
        <span>PLA</span>
      </label>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=Silk PLA&'))} />
        <span>Silk PLA</span>
      </label>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=PetG&'))} />
        <span>PetG</span>
      </label>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=ABS&'))} />
        <span>ABS</span>
      </label>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=Carbon&'))} />
        <span>Carbon</span>
      </label>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=Nylon&'))} />
        <span>Nylon</span>
      </label>
      <label className='label_filter'>
        <input type='radio' className='option' name='option_printAresa' onClick={() => dispatch(changeParams('contains[typeFilament]=Tpu&'))} />
        <span>TPU</span>
      </label>
    </>)}
</div>
  )
}
