import React from 'react'
import Header from '../Header'
import Nav from '../Nav'

export default function NotFound() {
  return (
    <React.Fragment>
        <Header />
        <Nav />
        <h2>Ошибка 404. Страница не найдена</h2>
        <p>Воспользуйтесь навигацией и перейдите на нужную Вам страницу.</p>
    </React.Fragment>
  )
}
