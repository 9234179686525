import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import ApiPhoto from '../ApiPhoto';
import ApiProductSpecifications from '../ApiProductSpecifications';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import AboutList from '../AboutList';
import { Helmet } from 'react-helmet-async';

export default function SoloProductPage() {
  const [items, setItems] = useState([]);
  const {productId} = useParams();
  const [productVariationId, setProductVartiationId] = useState(productId);
  const variationClick = (value) => {
    setProductVartiationId(value)
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    fetch(`https://3dsyndicate-pereklichka.ru/api/productsWithPhoto/${productId - 2}`,{
      headers: {
        'Access-Control-Allow-Origin' : '*',
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      }
    })
    .then(res => res.json())
    .then(
      (result) => {
        setItems(result);
      },
    )

  }, [productId]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>3D принтеры - Купить 3D принтер в Санкт-Петербурге. Creality, Anycubic, BambuLab, FlyngBear. Новый 3D принтер для печати. Работа с ЮР. Лицами.</title>
        <meta  name='description' content='3D принтер для дома и производства. Купить 3D принтер.'/>
        <meta name="keywords" content="3д принтер, купить, fdm, sla, мойка, сушилка " />
      </Helmet>
    <Header />
    <Nav />
    <main className='product_page_main'>
    {items.printers && (
      <>
      <ApiPhoto items={items.photo} productId={`${productVariationId}`}/>
      <ApiProductSpecifications variationClick={variationClick} variationId={productVariationId} items={items.printers[0]} />
      </>)}
    </main>
    <AboutList />
    <SocialBlock />
    <YandexMap />
    <Footer />
  </React.Fragment>
  )
}
