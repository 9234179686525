import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { IoIosClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { updateStateNav } from '../features/mobileNavSlice';
import { IoIosArrowBack } from "react-icons/io";
import MarketplacesBlack from './MarketplacesBlack';

export default function MobileNav() {
const dispatch = useDispatch();
const [hidden, setHidden] = useState(false);

function mouseDownMobileNav(e){
  if(e.target.closest('.mobile_navigator') == null){
    dispatch(updateStateNav());
}
};

function slide(value){
    const firstMenu = document.getElementById('first_menu');
    const serviceMenu = document.getElementById('service_menu');
    const backMenu = document.getElementById('svg_back_menu');

    switch(value){
        case 'service':
            firstMenu.style = 'display: none;';
            serviceMenu.style = 'display: flex;';
            backMenu.style = 'display: block;';
            break;
        case 'back':
            firstMenu.style = 'display: flex;';
            serviceMenu.style = 'display: none;';
            backMenu.style = 'display: none;';
            break;
        default:
            break;
    }
}
  return (
    <div className='main_mobile_navigator_shell' onMouseDown={(e) => mouseDownMobileNav(e)}>
        <div className='mobile_navigator'>
            <div className='mobile_menu_svg'>
                <IoIosArrowBack className='mobile_nav_svg' id='svg_back_menu' onClick={()=> slide('back')}/>
                <IoIosClose className='mobile_nav_svg' id='svg_close_menu' onClick={()=> dispatch(updateStateNav())} />
            </div>
            <div className='second_mobile_navigator' id='first_menu'>
              <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                <Link to='/printers'>3D Принтеры</Link>
              </div>
              <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                <Link to='/filament'>Филамент</Link>
              </div>
              <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                <Link to='/spareParts'>Запчасти</Link>
              </div>
              <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                <Link to='/toys'>Игрушки и интерьер</Link>
              </div>
              <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                <Link to='/service'>Cервис 3D принтеров</Link>
              </div>
              <div onClick={()=>slide('service')} className='main_mobile_item'>
                <span className='main_mobile_item_span'>3D Услуги</span>
              </div>
              <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                <Link to='/tradeIn'>Trade In</Link>
              </div>
            </div>
            <div className='second_mobile_navigator' id='service_menu'>
                <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                    <Link to='/scanning'>Сканирование</Link>
                </div>
                <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                    <Link to='/modeling'>Моделирование</Link>
                </div>
                <div className='main_mobile_item' onClick={()=> dispatch(updateStateNav())}>
                    <Link to='/printing'>Печать</Link>
                </div>
            </div>
            {hidden && (<div className='mobile_nav_marketplaces' id='mobile_nav_marketplaces'>
              <MarketplacesBlack />
            </div>)}
            <div className='mini_mobile_nav'>
                <div className='main_mobile_item_mini'>
                  <span onClick={()=> setHidden(!hidden)} >Маркетплейсы</span>
                </div>
                <div className='main_mobile_item_mini' onClick={()=> dispatch(updateStateNav())}>
                  <Link to="/main/warranty"><span>Гарантии</span></Link>
               </div>
               <div className='main_mobile_item_mini' onClick={()=> dispatch(updateStateNav())}>
                <Link to="/main/delivery"><span>Доставка</span></Link>
               </div>
               <div className='main_mobile_item_mini' onClick={()=> dispatch(updateStateNav())}>
               <Link to="/main/about"><span>О нас</span></Link>
               </div>
              </div>
            <div className='second_mobile_navigator' id='marketplaces_menu'>
            </div>
        </div>
    </div>
  )
}
