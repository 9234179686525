import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import ApiSparePartsSpecifications from '../ApiSparePartsSpecifications';
import ApiPhoto from '../ApiPhoto';
import AboutList from '../AboutList';
import { Helmet } from 'react-helmet-async';


export default function SoloSparePartsPage(props) {
  const [items, setItems] = useState([]);
  const {productId} = useParams();
  const [productVariationId, setProductVartiationId] = useState(productId)
  const variationClick = (value) => {
    setProductVartiationId(value)
  }
    useEffect(() => {
      window.scrollTo({
        top: 100,
        left: 0,
        behavior: 'smooth'
      });
      fetch(`https://3dsyndicate-pereklichka.ru/api/sparePartsUnion/${productId - 2}`,{
        headers: {
          'Access-Control-Allow-Origin' : '*',
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        }
      })
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
      )
    }, [props, productId]);    
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Запчасти для 3D принтера. Купить запчасти в Санкт-Петербурге. Hotend, сопло, термоблок, экструдер. Creality K1, FlyingBear. Работа с Юр.Лицами.</title>
        <meta  name='description' content='Запчасти, расходные материалы, комплектующие для любых 3D принтеров. '/>
        <meta name="keywords" content="запчасть, ремонт, для 3д принтера, деталь, запасные, плата, сопло" />
      </Helmet>
    <Header />
    <Nav />
    <main className='product_page_main'>
    {items.spareParts && (
      <>
      <ApiPhoto items={items.photo} productId={`${productVariationId}`} />
      <ApiSparePartsSpecifications variationClick={variationClick} variationId={productVariationId} items={items}/>
      </>)}
    </main>
    <AboutList />
    <SocialBlock />
    <YandexMap />
    <Footer />
  </React.Fragment>
  )
}
