import React from 'react';
import { YMaps, Map, Placemark} from '@pbe/react-yandex-maps';
import GetCalLMap from './GetCalLMap';
import placeMarkPhote from '../img/placemark.png';

export default function YandexMap() {
    return (
    <div className='yandex_map'>
        <GetCalLMap />
        <YMaps>
            <Map defaultState={{ center: [59.874359, 30.310523], zoom: 11, controls: ["fullscreenControl"]}} width={`100%`} height={`500px`} modules={["control.ZoomControl", "control.FullscreenControl"]} >
                    <Placemark modules={["geoObject.addon.balloon"]} geometry={[59.874359, 30.310523]} options={{iconLayout: 'default#image', iconImageHref: placeMarkPhote, iconImageSize: [70, 70 ], iconImageOffset: [-30, -70]}} properties={{balloonContentBody:"Вход через магазин дикси, домофон 7"}}/>
            </Map>
        </YMaps>
    </div>
  )
}