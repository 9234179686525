import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import FilterProducts from '../FilterProducts';
import ApiProducts from '../ApiProducts';
import { Helmet } from 'react-helmet-async';


export default function PrintersPage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>3D Принтеры - Купить 3D принтер в СПБ. FDM, LCD. Печать для производства и дома.</title>
        <meta  name='description' content='Каталог 3D принтеров. Приобрести не дорого 3D принтер в Санкт-Петербурге.'/>
        <meta name="keywords" content="принтера, купить 3д принтер, фотополимерник, печать пластиком, филамент, creality" />
      </Helmet>
    <Header />
    <Nav />
    <main className='main_catalog_prodcuts'>
      <FilterProducts />
      <ApiProducts apiRequest="productsPrinters" typeProduct={"typePrinter"} typeProductName={"принтера"}/>
      <meta name="keywords" content="3д принтер, creality, купить, anycubic" />
    </main>
    <Footer />
  </React.Fragment>
  )
}
