import {React, useRef, useEffect} from 'react'
import { register } from 'swiper/element/bundle'
register();

export default function ApiPhoto(props) {
    const swiperRef = useRef(null);

    useEffect(() => {

      register();

      const params = {
        slidesPerView: 1,
        speed: 500,
        autoplay: {
          delay: 40000,
        },
      };

      Object.assign(swiperRef.current, params);

      swiperRef.current.initialize();
    }, [props])
      return (
        <div className='swiper_product'>
          
        <swiper-container style={{
        "--swiper-navigation-color": "#4A4F53", 
        "--swiper-pagination-color": "#D5001C",
        "--swiper-pagination-bullet-size": "5px",
        "--swiper-navigation-size": "25px",
        }}
         pagination="true" navigation="true" init="false" ref={swiperRef}>
          {props.items.map(item => (
            parseInt(item.product_id) === parseInt(props.productId) &&
              (<swiper-slide key={item.id}><img alt='Слайдер с основными фото товара' src={`${item.url_photo}`}/></swiper-slide>)
          ))}
        </swiper-container>
        </div>
      );
  }
  
