import React from 'react';
import SocialIcons from './SocialIcons';
import MessangerIcons from './MessangerIcons';
import sindicatLogo from '../img/sindicatLogo.png';
import MarketplacesWhite from './MarketplacesWhite';

export default function SocialBlock() {
  return (
    <div className='social_block'>
      <div className='social_block_media'>
        <span className='social_block_span'>Наши социацильные сети</span>
        <SocialIcons />
        <span className='social_block_span'>Наши товары на маркетплейсах</span>
        <div className='product_specification_mp_icons'>
        <MarketplacesWhite />
         </div>
      </div>
      <div className='social_block_logo_sindicat'>
        <img src={sindicatLogo} alt='Логотип компании Синдикат' />
      </div>
      <div className='social_block_media'>
        <span>Санкт-Петербург Варшавская 23А.</span>
        <a href="tel:+79110276851">+7 (911) 027-68-51</a>
        <span className='social_block_span'>Связаться с помощью месснджера</span>
        <MessangerIcons />
        </div>
    </div>
  )
}
