import React, { useEffect } from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import YandexMap from '../YandexMap';
import SwiperImg from '../SwiperImg';
import AboutBlock from '../AboutBlock';
import AboutList from '../AboutList';
import SocialBlock from '../SocialBlock';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export default function MainPage() {
  const{type} =  useParams();
  useEffect(() => {
    switch (type){
      case 'warranty':
        document.querySelector('.about_block_text').scrollIntoView({behavior: 'smooth'});
        break;
      case 'delivery':
        document.querySelector('.about_block_text').scrollIntoView({behavior: 'smooth'});
        break;
      case 'about':
        document.querySelector('.about_block_text').scrollIntoView({behavior: 'smooth'});
        break;
      default:
        break;
    }
  }, [type])

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Студия 3D печати Синдикат 3D. Магазин 3D принтеров, пластика, запчастей и игрушек. 3D моделирование, сканирование и печать. Сервис 3D принтеров.</title>
        <meta  name='description' content='Синдикат 3D - Студия 3D печати. Продажа, ремонт и обслуживание 3D принтеров. 3D моделирование, сканирование, печать на заказ. Работа с Юр. Лицами.'/>
        <meta name="keywords" content="3д печать, принтер, купить, Сантк-Петербург, спб, печать детали на заказ, моделирование, сканирование, опт, юр лицо, счет, сервис, ремот" />
      </Helmet>
      <Header />
      <Nav />
      <main>
        <SwiperImg />
        <AboutBlock />
        <AboutList />
        <SocialBlock />
        <YandexMap />
      </main>
      <Footer />
    </React.Fragment>
  )
}
