import React from 'react'
import wb_img from '../img/marketplaces/wb.png';
import ozon_img from '../img/marketplaces/ozon_96.png';
import yandex_img from '../img/marketplaces/yandex_3.png';
import GetCall from './GetCall';
import MessangerIcons from './MessangerIcons';

export default function ApiProductSpecifications(props) {
      return (
        <>
        <div className='product_specifications'>
            <div className='product_specifications_name'>{props.items.name}</div>
            <div className='product_specifications_block'>
              <div className='product_specifications_name_block'>
                {props.items.manufacturer && (<div className='product_specifications_item_name product_specifications_nmanufacturer'>Производитель </div>)}
                {props.items.printArea && (<div className='product_specifications_item_name product_specifications_printArea'>Область печати </div>)}
                {props.items.speedPrint && (<div className='product_specifications_item_name product_specifications_speedPrint'>Скорость печати </div>)}
                {props.items.housingPrinter && (<div className='product_specifications_item_name product_specifications_housingPrinter'>Тип корпуса </div>)}
                {props.items.amsSystem && (<div className='product_specifications_item_name product_specifications_amsSystem'>AMS система</div>)}
                {props.items.typePrinter && (<div className='product_specifications_item_name product_specifications_typePrinter'>Тип принтера</div>)}
              </div>
              <div className='product_specifications_defis_block'>
                {props.items.manufacturer && (<div className='product_specifications_item_defis'>—</div>)}
                {props.items.printArea && (<div className='product_specifications_item_defis'>—</div>)}
                {props.items.speedPrint && (<div className='product_specifications_item_defis'>—</div>)}
                {props.items.housingPrinter && (<div className='product_specifications_item_defis'>—</div>)}
                {props.items.amsSystem && (<div className='product_specifications_item_defis'>—</div>)}
                {props.items.typePrinter && (<div className='product_specifications_item_defis'>—</div>)}
              </div>
              <div className='product_specifications_value_block'>
                {props.items.manufacturer && (<div className='product_specifications_item_value product_specifications_nmanufacturer'>{props.items.manufacturer}</div>)}
                {props.items.printArea && (<div className='product_specifications_item_value product_specifications_printArea'>{props.items.printArea}</div>)}
                {props.items.speedPrint && (<div className='product_specifications_item_value product_specifications_speedPrint'>{props.items.speedPrint}</div>)}
                {props.items.housingPrinter && (<div className='product_specifications_item_value product_specifications_housingPrinter'> {props.items.housingPrinter}</div>)}
                {props.items.amsSystem && (<div className='product_specifications_item_value product_specifications_amsSystem'>{props.items.amsSystem}</div>)}
                {props.items.typePrinter && (<div className='product_specifications_item_value product_specifications_typePrinter'>{props.items.typePrinter}</div>)}
              </div>
              <div className='product_specifications_buy_block'>
                <div className='product_specifications_price'>{props.items.price} <span style={{fontSize : "18px"}}>₽/шт</span></div>
                <div className='product_specifications_status'>{props.items.product_status}</div>
                <GetCall />
                <span className='product_specification_write_messanger'>Написать нам в мессенджере:</span>
                <MessangerIcons />
              </div>
            </div>
            {props.items.ozon_url && (<>
            <span style={{fontSize:"14px", fontWeight: "400" }}>Товар на маркетплейсах:</span>
            <div className='product_specification_mp_icons'>
              <div className='product_specifications_ozon_url product_specifications_url'><a href={props.items.ozon_url} target='_blank' rel="noopener noreferrer"><img height={'48px'} src={ozon_img} alt='Ссылка на данный товар на озоне' /> </a></div>
              <div className='product_specifications_wb_url product_specifications_url'><a href={props.items.wb_url} target='_blank' rel="noopener noreferrer"><img height={'40x'}src={wb_img} alt='Ссылка на данный товар на вайлдберриз' /> </a></div>
              <div className='product_specifications_yandex_url product_specifications_url'><a href={props.items.yandex_url} target='_blank' rel="noopener noreferrer"><img height={'40px'} src={yandex_img} alt='Ссылка на данный товар на яндекс маркете' /> </a></div>
            </div></>)}
        </div>

        </>
      );
};
