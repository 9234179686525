import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import AboutList from '../AboutList';
import serviceImg from '../../img/SliderImg/service.png'
import { GiBrokenAxe } from "react-icons/gi";
import { GiAutoRepair } from "react-icons/gi";
import img_three from '../../img/service/service_three.png'
import img_history_one from '../../img/service/1.jpg'
import img_history_two from '../../img/service/2.jpg'
import img_history_three from '../../img/service/3.jpg'
import { Helmet } from 'react-helmet-async';


export default function ServicePage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Сервис 3D принтеров Санкт-Петербург. Ремонт и обслуживание принтеров с гарантией. Работа с Юр. Лицами.</title>
        <meta  name='description' content='Ремонт и сервис 3D принтеров в Санкт-Петербурге. Замена хотенда. Запчасти. Работа с ЮР. Лицами.'/>
        <meta name="keywords" content="ремонт, сервис, 3д принтера, спб, обслуживание, замена хотенда, починить принтер" />
      </Helmet>
    <Header />
    <Nav />
    <main>
      <div className='service_main_content'>
        <div className='service_main_photo'>
          <img src={serviceImg} alt='head img service'></img>
        </div>
        <div className='service_main_block'>
          <div className='service_main_block_photo'><img id='service_main_block_photo' src={img_three} alt='about img service'/></div>
          <div className='service_main_block_text'>
            <span>О нашем сервисе</span>
            <p>Ремонт и обслуживание 3D  принтеров качественно и в срок.<br/>
            Мы занимаемся гарантийным и послегарантийным ремонтом 3D принтеров.<br/> Ремонтируем FDM и фотополимерные принтера. Обслуживаем фермы и парки 3D принтеров  физических и юридических лиц.<br/> 
            Собственный склад запасных частей.<br/>
            При возможности и необходимости предоставляем в период ремонта подменные принтера. <br/><br/>
            Для организаций мы предоставляем всю необходимую бухгалтерскую документацию.
            Работаем в ЭДО.<br/>
            Есть доставка собственным автотранспортом крупногабаритных 3D принтеров.<br/> 
            Ремонтируем ТОЛЬКО 3D принтера.<br/><br/>
            Для многих производителей 3D принтеров запчасти в наличии. Creality, BamBulab, Anycubic, Elegoo , FlyingBear 
            Также ремонтируем Picasso, Tronxy, Геркулес и т.д
            Для юрлиц и ферм – специальные условия! Ремонт без очереди и на выезде!
            Есть услуга срочного ремонта.
              </p>
              <div className='service_text_list'>
              <ul id='text_list_one'>
                <h2><GiAutoRepair />Услуги</h2>
                <li>Устранение течи хотенда</li>
                <li>Переборка хотенда</li>
                <li>Замена хотенда</li>
                <li>Калибровка принтера</li>
                <li>Замена сопла</li>
                <li>Ремонт материнской платы</li>
                <li>Регламентное обслуживание</li>
                <li>Замена приводных ремней</li>
                <li>Ремонт кинематики</li>
                <li>Компиляция прошивки.</li>
                <li>Исправление прошивок</li>
              </ul>
              <ul id='text_list_two'>
                <h2><GiBrokenAxe />Типовые неисправности</h2>
                <li>Не печатает</li>
                <li>Не выдавливает пластик</li>
                <li>Течь пластика</li>
                <li>Пробка в 3D принтере</li>
                <li>Не прилипает пластик к столу</li>
                <li>Пропуск слоев при печати</li>
                <li>Шум вентиляторов при печати</li>
                <li>Щелкает экструдер при печати</li>
                <li>Не видит SD карту</li>
                <li>Не включается</li>
                <li>Не нагревается хотенд</li>
              </ul>
              </div>
          </div>
        </div>
        <h2 className='service_history_title'>Примеры работ сервиса</h2>
        <div className='service_history'>
          <div className='service_history_item'>
           <img className='service_history_photo' src={img_history_one} alt='Ошибка Creality k1 key:2522'></img>
           <div className='service_history_text'>В работе Creallity K1 max – не печатает,  ошибка key: 2522, Аномалия передачи данных датчика уровня. В ходе диагностики выявили неисправный тензодатчик датчик стола. На нашем складе они есть в наличии. Заменили неисправный тензодатчик на новый. Обновили прошивку. Принтер готов.</div> 
          </div>
          <div className='service_history_item'>
           <img className='service_history_photo' src={img_history_two} alt='Хотенд залило пластиком Creality k1 max'></img>
           <div className='service_history_text'>Creality K1 – течет пластик. Принтер не печатает. Разобрали, отдефектовали. К замене хотенд целиком и радиатор. Запчасти в наличии. 2 часа работы – принтер готов.</div>   
          </div>
          <div className='service_history_item'>
            <img className='service_history_photo' src={img_history_three} alt='Заклинило мотор Mono 3'></img>
            <div className='service_history_text'> Фотополимерный принтер Mono M3. Стол не поднимаеется. Разобрали, отдефектовали. Смола залила подшипники мотора Z. Вымыли принтер от смолы. Заменили подшипники. Принтер заработал. Стол передвигается плавно, посторонних звуков нет. Выдаем клиенту.</div>    
          </div>
        </div>
      </div>
      <AboutList />
      <SocialBlock />
      <YandexMap />
    </main>
    <Footer />
  </React.Fragment>
  )
}
