import React from 'react'
import yandex_white_img from '../img/marketplaces/yandex_3_white.png'
import wb_white_img from '../img/marketplaces/wb_white.png'
import ozon_white_img from '../img/marketplaces/ozon_white.png'

export default function MarketplacesWhite() {
  return (
    <>
        <div className='product_specifications_ozon_url'><a href='https://www.ozon.ru/seller/3d-studiya-sindikat-1197788/products/?miniapp=seller_1197788' target='_blank' rel="noopener noreferrer"> <img height={'38px'} src={ozon_white_img} alt='ССыллка на наши товары на озоне' /></a></div>
        <div className='product_specifications_wb_url'><a href='https://www.wildberries.ru/seller/4139501' target='_blank' rel="noopener noreferrer"> <img height={'30x'} src={wb_white_img} alt='ССыллка на наши товары на вайлдберриз' /></a></div>
        <div className='product_specifications_yandex_url'><a href='https://market.yandex.ru/business--studiia-3d/80665632' target='_blank' rel="noopener noreferrer"> <img height={'32px'} src={yandex_white_img} alt='ССыллка на наши товары на яндекс маркете' /></a></div>
    </>
  )
}
