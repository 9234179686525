import React, { useState } from 'react'
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';

export default function FilterAmsSystem() {
    const [hidden, setHidden] = useState(false);
    const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_amsSystem'>
    <div className='amsSystem_title filter_second_title' onClick={()=>setHidden(!hidden)}>AMS система <FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
    {!hidden && (
        <> 
        <label className='label_filter open'>
      <input type='checkbox' className='option' name='option_ams' onClick={() => dispatch(changeParams('in[amsSystem][]=Да&'))}value={'true'}/>
      <span>Есть</span>
    </label>
    <label className='label_filter closed'>
      <input type='checkbox' className='option' name='option_ams' onClick={() => dispatch(changeParams('in[amsSystem][]=Нет&'))} value={'false'}/>
      <span>Нету</span>
    </label>
    </>)}
    </div>
  )
}
