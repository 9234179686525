import React from 'react'

export default function Input(props) {
  return (
    <label htmlFor="input_getCall_name" className="getCall_input">
        <input type="text" id={`input_getCall_${props.id}`} name={`${props.name}`} placeholder="&nbsp;" autoComplete="off" />
        <span className="span_label_name">{props.text}</span>
        <span className="focus-bg"></span>
    </label>
  )
}
