import React from 'react';
import { FaTelegram } from "react-icons/fa";
import { FaVk } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

export default function SocialIcons() {
  return (
    <div className="header_social">
        <a href='https://t.me/sindikat3d' target='_blank' rel="noopener noreferrer"><FaTelegram className='social_icon telegram'></FaTelegram></a>
        <a href='https://vk.com/3dstudio1' target='_blank' rel="noopener noreferrer"><FaVk className='social_icon vk' ></FaVk></a>
        <a href='https://www.youtube.com/channel/UCUIjmeBZBt3MOknc424sCIQ' target='_blank' rel="noopener noreferrer"><FaYoutube className='social_icon youtube'/></a>
    </div>
  )
}
