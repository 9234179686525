import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import ServiceNav from '../ServiceNav';
import SocialBlock from '../SocialBlock';
import YandexMap from '../YandexMap';
import GetCall from '../GetCall';
import SliderService from '../SliderService';
import EquipmentService from '../EquipmentService';
import headImg from '../../img/3dservie/scanning1.png'
import { Helmet } from 'react-helmet-async';

export default function ServiceScanningPage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>3D Сканирование Санкт-Петербург. Сканирование детали для создания 3D модели и печати на 3D принтере. Сканирование технический деталей, художественный изделий и людей.</title>
        <meta  name='description' content='3D Сканирование моделей и людей в Санкт-Петербурге. 3D печать.'/>
        <meta name="keywords" content="Сканирование, моделирвование для 3д печати, человека и деталей" />
      </Helmet>
      <Header />
        <Nav />
        <main className='three_service_main'>
          <div className='modeling_head'>
            <div className='modeling_head_text'>
              <div className='modeling_head_text_title'>3D Сканирование</div>
              <h4 className='modeling_head_text_h2'>Какой сканированием мы занимаемся?</h4>
              <p className='modeling_head_text_p'>Мы специализируемся на оптическом сканировании.
                 Сканер считывает геометрию объекта и создает его точную копию в виде облака точек. </p>
              <h4 className='modeling_head_text_h2'>Преимущества работы с нами:</h4>
              <p className='modeling_head_text_p'> 
              •  Мы работаем с любыми предоставленными моделями, независимо от размера и детализации. Отсканировать можно все что угодно, включая человека или животное.<br/>
              • Предоставляем услугу дальнейшей обработки скана объекта. Мы доводим модель до идеала, чтобы распечатать ее на 3D принтере.<br/>
              • Готовы обеспечить дальнейшее оптовое производство ваших моделей.<br/>
              • Заранее обговариваем сроки и стоимость выполнения проектов.</p>

              <h4 className='modeling_head_text_h2'>Как заказать 3D сканирование:</h4>
              <p className='modeling_head_text_p'>
                1) Подготовить объект для сканирования.<br/>
                2) Связаться с нами в удобном для вас мессенджере (WhatsApp, Telegram) для уточнения стоимости и сроков работы.<br/>
                3) Привезти объект для сканирования в нашу студия или заказать нашу доставку.</p>
              <h4 className='modeling_head_text_h2'>Обратите внимание, что минимальная стоимость сканирования от 5 000 рублей.</h4>
              <GetCall />          
            </div>
            <div className='modeling_head_img'><img src={headImg} alt='Реализованный проект моделирования, противотуманная фара на 3D принтере' />
              <div className='modeling_head_img_alt'>Реализованный проект противотуманной фары</div>
            </div>
          </div>
          <p className='service_galary_title'>Галерея наших работ</p> 
          <SliderService />
          <EquipmentService />
          <ServiceNav />
          <SocialBlock />
        </main>
        <YandexMap />
        <Footer />
      </React.Fragment>
      )
    }
