import React from 'react'
import { CiFilter } from 'react-icons/ci'
import { useDispatch } from 'react-redux'
import { resetParams } from '../features/paramsLinkSlice'


export default function FilterHead() {
    const dispatch = useDispatch();
  return (
    <div className='filter_head'>
        <div className='filter_title'><CiFilter className='filter_title_icon' />Фильтр</div>
        <input type="button" className='filter_reset' value={'Сбросить все'} onClick={() => dispatch(resetParams())}></input>
    </div>
  )
}
