import React from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { FaHeadset } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa6";
import { GiAutoRepair } from "react-icons/gi";
import { FaShop } from "react-icons/fa6";
import { GoLaw } from "react-icons/go";

export default function AboutList() {
  return (
    <div className='about_list'>
        <div className='about_list_item' >
            <div className='about_list_item_icon'><FaCheckCircle /></div>
            <div className='about_list_item_title'><span>Гарантия</span></div>
            <div className='about_list_item_paragraph'><p>Мы предоставляем собственную гарантию на все товары в нашем ассортименте, а так же на предоставленные услуги, чтобы Вы не беспокоились о некачесвтенной продукции.</p></div>
        </div>
        <div className='about_list_item' >
            <div className='about_list_item_icon'><GiAutoRepair /></div>
            <div className='about_list_item_title'><span>Собственный сервис</span></div>
            <div className='about_list_item_paragraph'><p>Мы создали собственный сервисный центр и наняли профессиональных инженеров-ремонтников, чтобы вы были спокойны за свой 3D принтер. Если с ним что-то случится, сразу звоните нам. Поможем максимально быстро и качественно.</p></div>
        </div>
        <div className='about_list_item' >
            <div className='about_list_item_icon'><FaShop /></div>
            <div className='about_list_item_title'><span>Магазин с шоурумом</span></div>
            <div className='about_list_item_paragraph'><p>Вы в любой момент можете прийти к нам в магазин и на деле увидеть как работаеют принтеры. Расскажем всё о 3D печати и покажем на примере нашей фермы. </p></div>
        </div>
        <div className='about_list_item' >
            <div className='about_list_item_icon'><GoLaw /></div>
            <div className='about_list_item_title'><span>Работа с Юр.Лицами</span></div>
            <div className='about_list_item_paragraph'>Мы уже длительное время работаем с множеством компаний и юр.Лиц. Обслуживаем большие фермы и поставляем оборудование на производсвта. Если Вы хотели бы начать сотрудничество, обращайтесь! Мы всегда поможем.</div>
        </div>
        <div className='about_list_item' >
            <div className='about_list_item_icon'><FaHeadset /></div>        
            <div className='about_list_item_title'><span>Тех. Поддержка</span></div>
            <div className='about_list_item_paragraph'>В любой момент Вы можете обратиться к нам за помощью, наши специалисты оперативно ответят как на технические вопросы, так и вопросы по нашему ассортименту.</div>
        </div>
        <div className='about_list_item' >
            <div className='about_list_item_icon'><FaUserShield /></div>
            <div className='about_list_item_title'><span>Качество и надежность</span></div>
            <div className='about_list_item_paragraph'><p>Для нас важна репутация, потому следим за качеством всей продукции в нашем магазине. И делаем всё возможное, чтобы Вы получили наилучший опыт 3D печати.</p></div>
        </div>
    </div>
  )
}
