import { createSlice } from "@reduxjs/toolkit";

export const mobileNavSlice = createSlice({
    name: 'mobileNav',
    initialState: {
        value: false,
    },
    reducers:{
        updateStateNav: (state) => {
            state.value = !state.value
        },
    },
})

export const {updateStateNav} = mobileNavSlice.actions

export default mobileNavSlice.reducer