import React, { useEffect, useState } from 'react';
import FilterPrice from './FilterPrice';
import StatusFilter from './StatusFilter';
import FilterManufacturer from './FilterManufacturer';
import FilterSpeedPrint from './FilterSpeedPrint';
import FilterPrintArea from './FilterPrintArea';
import FilterHousingPrinter from './FilterHousingPrinter';
import FilterAmsSystem from './FilterAmsSystem';
import FilterTypePrinter from './FilterTypePrinter';
import SideBar from './SideBar';
import FilterHead from './FilterHead';
import { IoIosArrowDown } from "react-icons/io";

export default function FilterProducts() {
  const [hidden, setHidden] = useState(false);
  
  useEffect(()=>{
    if(window.innerWidth < 700){
      setHidden(true)
    }
  },[])

  return (
    <div className='sidebar'>
        <SideBar />
        <div className='filter_main'>
            <FilterHead />
            <div><IoIosArrowDown className={`hide_filter ${!hidden}`} onClick={()=>setHidden(!hidden)}/></div>
            {!hidden &&(<>
            <FilterPrice />
            <FilterTypePrinter />
            <StatusFilter />
            <FilterManufacturer />
            <FilterSpeedPrint />
            <FilterPrintArea />
            <FilterHousingPrinter />
            <FilterAmsSystem /></>)}
        </div>
    </div>
  )
}