import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import ServiceNav from '../ServiceNav';
import EquipmentService from '../EquipmentService';
import SliderService from '../SliderService';

import { Helmet } from 'react-helmet-async';

export default function ThreeDServicePage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>3D печать, моделирование и сканирование в Санкт-Петербурге на заказ. Заказать печать детали на 3D принтере. Работа оптом и с Юр. Лицами.</title>
        <meta  name='description' content='Услуги 3D печати, моделирования и сканирования. Реверс инжеринг любой модели и печать на 3D принтере.'/>
        <meta name="keywords" content="3д печать, моделирование, сканирование спб" />
      </Helmet>
    <Header />
    <Nav />
    <main className='three_service_main'>
      <h2 className='three_service_title'>
        3D Услуги
      </h2>
      <p className='three_service_text'>
        Мы предлагаем полный комплекс 3D услуг, чтобы ваши идеи превратились в жизнь. Вы можете обратиться к нам 
        на любом этапе реализации вашего проекта, наши профессионалы помогут 
        воссоздать идею с нуля, воссоздать с помощью сканера или напечатать, в том числе, в больших объемах.
      </p>
      <ServiceNav />
      <p className='service_galary_title'>Галерея наших работ</p> 
      <SliderService />
      <EquipmentService />
      <SocialBlock />
    </main>
    <YandexMap />
    <Footer />
  </React.Fragment>
  )
}
