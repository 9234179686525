import React from 'react'

export default function button() {
  function sendRequest(event){

    const formElemnt = document.getElementById('get_call_form');
    const formData = new FormData(formElemnt);
    const toMysqlFormat = (date) => date.toISOString().split('T').join(' ').slice(0, 19);

    event.preventDefault();

    fetch(`https://3dsyndicate-pereklichka.ru/api/callRequests?`,{
      method: 'post',
      body: JSON.stringify({name: formData.get('name'), phone: formData.get('phone'), date_request: toMysqlFormat(new Date())}),
      headers: {
          'content-type': 'application/json'
      }
    }).then((response) => {
      if (response.status === 400) {
        const statusRequestSuccses = document.getElementById('status_reqest_success');
        const statusRequestFold = document.getElementById('status_reqest_fold');
        statusRequestFold.style = 'display: block';
        statusRequestSuccses.style = 'display: none';
      }else if(response.status === 200){
        document.getElementById('button_send_call').disabled = true;
        const statusRequestFold = document.getElementById('status_reqest_fold');
        const statusRequestSuccses = document.getElementById('status_reqest_success');
        statusRequestSuccses.style = 'display: block';
        statusRequestFold.style = 'display: none';
      }
    })
  }  
  return (
    <div className="button_send">
        <button onClick={(event) => sendRequest(event)} id='button_send_call' className="button">
            <svg width="180px" height="60px" viewBox="0 0 180 60" className="button_border">
                <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
                <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
            </svg>
            <span>Отправить</span>
        </button>
      </div>
  )
}
