import React, {useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';

export default function FilterPrice() {

  const dispatch = useDispatch();
  let [minVal, setMinVal] = useState(0);
  let [maxVal, setMaxVal] = useState(100000);
  let [priceInputMin, setPriceInputMin] = useState(0);
  let [priceInputMax, setPriceInputMax] = useState(100000);
  let [minTooltip, setMinTooltip] = useState('₽0');
  let [maxTooltip, setMaxTooltip] = useState('₽150000');
  let minGap = 1000;
  let sliderMinValue = parseInt(0);
  let sliderMaxValue = parseInt(200000);
  let [leftRange, setLeftRange] = useState('3%');
  let [rightRange, setRightRange] = useState('50%');

  function dispatchValue(type, event, value){
    if (type === 'max' && event.key === 'Enter'){
      inputPriceMax(event);
      dispatch(changeParams(`max[price]=${value}&`));
    }else if (type === 'min' && event.key === 'Enter'){
      inputPriceMin(event);
      dispatch(changeParams(`min[price]=${value}&`));
    }
  }

  function slideMin(event){
    let gap = parseInt(maxVal) - parseInt(minVal);
    if(gap <= minGap){
      setMaxVal(parseInt(minVal) + minGap);
    }
    if(maxVal > sliderMaxValue){
      setMaxVal(sliderMaxValue);
    }
    setMinVal(event.target.value)
  }

  function slideMax(event){
    let gap = parseInt(maxVal) - parseInt(minVal);
    if(gap <= minGap){
      setMinVal(parseInt(maxVal) - minGap);
    }
    if(minVal < sliderMinValue){
      setMinVal(sliderMinValue);
    }
    setMaxVal(event.target.value)
  }
  
  function inputPriceMin(event){
    if(event.target.value < sliderMinValue){
      setPriceInputMin(sliderMinValue);
    }
    if (event.key === "Enter") {
      setPriceInputMin(parseInt(event.target.value));
      setMinVal(parseInt(event.target.value));
    }
    
  }

  function inputPriceMax(event){
    if(event.target.value < minVal){
      setMinVal(event.target.value - minGap);
    }
    if (event.key === "Enter") {
      setMaxVal(parseInt(event.target.value));

    }
  }

  useEffect(() => {
    setPriceInputMin(minVal);
    setPriceInputMax(maxVal);
    setMinTooltip('₽' + minVal);
    setMaxTooltip('₽' + maxVal);
    setLeftRange((minVal / sliderMaxValue) * 100 + "%");
    setRightRange(100 - (maxVal / sliderMaxValue) * 100 + "%");
  }, [maxVal, minGap, minVal, sliderMaxValue])

  return (
    <div className='filter_price'>
    <div className='range_title filter_second_title'>Цена</div>
    <div className='range_slider'>
      <span style={{left:leftRange, right:rightRange}} className='slider_track'></span>
      <input type='range' name='min_val' className='min_val' min={0} max={200000} onClick={() => dispatch(changeParams(`min[price]=${minVal}&`)) } onTouchEnd={() => dispatch(changeParams(`min[price]=${minVal}&`))} value={minVal} onInput={slideMin} ></input>
      <input type='range' name='max_val' className='max_val' min={0} max={200000} onClick={() => dispatch(changeParams(`max[price]=${maxVal}&`)) } onTouchEnd={() => dispatch(changeParams(`max[price]=${maxVal}&`))} value={maxVal} onInput={slideMax}></input>
      <div style={{left:leftRange}} className="tooltip min_tooltip">{minTooltip}</div>
      <div style={{right:rightRange}} className="tooltip max_tooltip">{maxTooltip}</div>
    </div>
    <div className='input_box'>
      <div className='min_box'>
        <div className='input_wrap'>
          <span className='input_addon'>₽</span>
          <input type='text' name='min_input' id='min_input' className='input_field min_input' value={priceInputMin} min={0} maxLength={5} onChange={(e)=>setPriceInputMin(e.target.value)} onBlur={(e)=> {setMinVal(e.target.value); dispatch(changeParams(`min[price]=${e.target.value}&`))}} onKeyDown={(e) => dispatchValue('min', e, e.target.value)}></input>
        </div>
      </div>
      <div className='max_box'>
        <div className='input_wrap'>
          <span className='input_addon'>₽</span>
          <input type='text' name='max_input' id='max_input' className='input_field max_input' value={priceInputMax} maxLength={5} onChange={(e)=>setPriceInputMax(e.target.value)} onBlur={(e)=> {setMaxVal(e.target.value); dispatch(changeParams(`max[price]=${e.target.value}&`))}} onKeyDown={(e) => dispatchValue('max', e, e.target.value)}></input>
        </div>
      </div>
    </div>
  </div>
  )
}
