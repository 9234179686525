import React from 'react'
import imgLogo from '../img/logo_black_mini.png';

export default function AboutBlock() {
  return (
    <div className='about_block'>
        <div className='about_block_logo'>
            <img src={imgLogo} alt='Лого 3D синдикат'/>
        </div>
        <div className='about_block_text'>
            <p className='about_block_title'>О компании</p>
            <div className='about_block_info_company'>
              <p className='about_block_item'>Мы студия 3D печати SYNDICATE 3D, у нас вы можете найти все, что потребуется на вашем пути в сфере 3D печати. 
                Занимаемся продажей 3D принтеров, запчастей и филаментов. Работаем с физическими и юридическими лицами, а также оптом.
              </p>
              <p className='about_block_item'>
              Предоставляем услуги 3D печати на наших высокоскоростных принтерах, услуги моделирования нашими специалистами и сканирования современным оборудованием.</p>
              <p className='about_block_item'>                
              Ассортимент наших товаров вы также можете найти на маркетплейсах Ozon, Wildberries и Яндекс Маркет.</p>
              <p className='about_block_item'>Собственная доставка: 
              Доставка по Санкт-Петербургу нашим надежным курьером. По России отправка компанией CDEK, в страны СНГ - Ozon.</p>
            </div>
        </div>
    </div>
  )
}
