import React from 'react'
import { useState } from 'react'
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function StatusFilter() {
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_availability'>
        <div className='availability_title filter_second_title' onClick={()=>setHidden(!hidden)}>Статус <FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
        {!hidden && (<>
        <label className='label_filter label_availability'>
          <input type='checkbox' className='option' name='option_status' onClick={() => dispatch(changeParams('in[product_status][]=В наличии&'))} />
          <span>В наличии</span>
        </label>
        <label className='label_filter label_availability'>
          <input type='checkbox' className='option' name='option_status' onClick={() => dispatch(changeParams('in[product_status][]=В пути&'))} />
          <span>В пути</span>
        </label>
        <label className='label_filter label_availability'>
          <input type='checkbox' className='option' name='option_status' onClick={() => dispatch(changeParams('in[product_status][]=Под заказ&'))} />
          <span>Под заказ</span>
        </label>
        </>)}
    </div>
  )
}
