import React from 'react'
import { Link } from 'react-router-dom'
import GetCall from './GetCall'

export default function Footer() {
  return (
    <footer>
      <div className='footer_main_content'>
        <div className='footer_info'>
          <div className='footer_addreses'>
            <span>Варшавская 23А.</span>
            <a href="tel:+79110276851">+7 (911) 027-68-51</a>
          </div>
          <GetCall />
        </div> 
        <div className='footer_nav'>
          <div className='footer_nav_item'>
            <div className='footer_nav_items_title'>
              <Link to="/printers">3D Принтеры</Link>
            </div>
              <Link to="/tradeIn">Trade In</Link>
              <Link to='/service'>Ремонт и сервис</Link>
            </div>
            <div className='footer_nav_item'>
            <div className='footer_nav_items_title'><Link to="/filament">Филамент</Link></div>
            </div>
            <div className='footer_nav_item'>
            <div className='footer_nav_items_title'><Link  to='/spareParts'>Запчасти</Link></div>
            </div>
            <div className='footer_nav_item'>
              <div className='footer_nav_items_title'><Link to='/service'>Услуги</Link></div>
              <Link to='/printing'>3D Печать</Link>
              <Link to='/scanning'>3D Сканирование</Link>
              <Link to='/modeling'>3D Моделирование</Link>
            </div>
            <div className='footer_nav_item'>
            <div className='footer_nav_items_title'><Link to='/toys'>Игрушки и интерьер</Link></div>
              <a href='https://www.ozon.ru/seller/3d-studiya-1-1-1197788/products/?miniapp=seller_1197788' target="_blank" rel="noreferrer">Ozon</a>
              <a href='https://market.yandex.ru/business--studiia-3d/80665632' target="_blank" rel="noreferrer">Яндекс Маркет</a>
              <a href='https://www.wildberries.ru/seller/4139501' target="_blank" rel="noreferrer">Wildberries</a>
            </div>
          </div>
        </div>
    </footer>
  )
}
