import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import FilterPrice from './FilterPrice'
import StatusFilter from './StatusFilter'
import FilterManufacturer from './FilterManufacturer'
import FilterTypeSpareParts from './FilterTypeSpareParts'
import FilterHead from './FilterHead'
import { IoIosArrowDown } from 'react-icons/io'

export default function FilterSpareParts() {
  const [hidden, setHidden] = useState(false);
  
  useEffect(()=>{
    if(window.innerWidth < 700){
      setHidden(true)
    }
  },[])
  return (
    <div  className='sidebar'>
        <SideBar />
    <div className='filter_main'>
        <FilterHead />
        <div><IoIosArrowDown className={`hide_filter ${!hidden}`} onClick={()=>setHidden(!hidden)}/></div>
        {!hidden &&(<>
        <FilterPrice />
        <StatusFilter />
        <FilterManufacturer />
        <FilterTypeSpareParts /></>)}
        </div>
    </div>
  )
}
