import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import FilterToys from '../FilterToys';
import ApiProducts from '../ApiProducts';
import { Helmet } from 'react-helmet-async';

export default function ToysPage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Продажа игрушек и товаров для дома. Игрушки, напечатанные на 3D принетре. Продажа игрушек для детей и в подарок. Игрушки оптом. Работа с Юр. Лицами.</title>
        <meta  name='description' content='Каталог игрушек и товаров для дома. Игрушки для детей оптом в Санкт-Петербурге. Работа с Юр.Лицами.'/>
        <meta name="keywords" content="Игрушки на 3д принтере, Соединители москитной сетки, крепление, рем комлект" />
      </Helmet>
    <Header />
    <Nav />
    <main className='main_catalog_prodcuts'> 
      <FilterToys />
      <ApiProducts apiRequest="productsToys" typeProduct={"typeToys"} typeProductName={""}/>
    </main>
    <Footer />
  </React.Fragment>
  )
}
