import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import FilterSpareParts from '../FilterSpareParts';
import ApiProducts from '../ApiProducts';
import { Helmet } from 'react-helmet-async';

export default function SparePartsPage() {
  return (
    <React.Fragment>
    <Header />
    <Nav />
    <main className='main_catalog_prodcuts'>
    <Helmet>
        <meta charSet='utf-8' />
        <title>Запчасти для 3D принтера. Все запчасти для Вашего принтера. Продажа запчастей оптом в Санкт-Петербурге. Работа с Юр. Лицами.</title>
        <meta  name='description' content='Каталог запчастей для всех 3D принетров. Комплектуюущие для ремонта и обслуживания 3D принтера.'/>
        <meta name="keywords" content="Запчасть для 3д принетра, ремонт, детали, обслуживание" />
      </Helmet>
      <FilterSpareParts />
      <ApiProducts apiRequest="productsSpareParts" typeProduct={"typeSpare"} typeProductName={"запчасти"} />
    </main>
    <Footer />
  </React.Fragment>
  )
}
