import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import ApiProducts from '../ApiProducts';
import FilterFilament from '../FilterFilament';
import { Helmet } from 'react-helmet-async';

export default function FilamentPage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Филамент для 3D принтера. Пластик для 3D печати. PLA, PetG, ABS, Nylon, Carbon, TPU. Трехцветный пластик.</title>
        <meta  name='description' content='Каталог пластика для 3D принтеров.'/>
        <meta name="keywords" content="филамент, пластик, 3д принтер, купить, Creality, Anycubic, Kingroo, катушка" />
      </Helmet>
    <Header />
    <Nav />
    <main className='main_catalog_prodcuts'>
      <FilterFilament />
      <ApiProducts apiRequest="productsFilament" typeProduct={"typeFilament"} typeProductName={"пластика"}/>
    </main>
    <Footer />
  </React.Fragment>
  )
}
