import React from 'react'
import { useState } from 'react'
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterSpeedPrint() {
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_speedPrint'>
        <div className='speedPrint_title filter_second_title' onClick={()=>setHidden(!hidden)}>Скорость печати <FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
        {!hidden && (<>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_speed' onClick={() => dispatch(changeParams('in[speedPrintVariation][]=SlowSpeed&'))} />
          <span>До 100мм/c</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_speed' onClick={() => dispatch(changeParams('in[speedPrintVariation][]=MediumSpeed&'))} />
          <span>От 100 до 300мм/c</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_speed' onClick={() => dispatch(changeParams('in[speedPrintVariation][]=HighSpeed&'))} />
          <span>От 300 до 600мм/c</span>
        </label>
        </>)}
    </div>
  )
}
