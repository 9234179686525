import React from 'react'
import GetCall from './GetCall'
import { Link } from 'react-router-dom'
import { IoIosMenu } from "react-icons/io";
import MobileNav from './MobileNav';
import { useDispatch, useSelector } from 'react-redux';
import { updateStateNav } from '../features/mobileNavSlice';



export default function Nav() {
const dispatch = useDispatch();
const mobileNavState = useSelector((state) => state.mobileNav.value)

  return (
    <div className='main_nav'>
      <IoIosMenu className='mobile_nav_svg' onClick={()=>dispatch(updateStateNav())}/>
      {mobileNavState &&(<MobileNav />)}
        <nav className='main_navigator'>
        <div className='main_nav_item'>
          <Link to='/printers'>3D Принтеры</Link>
        </div>
        <div className='main_nav_item'>
          <Link to='/filament'>Филамент</Link>
        </div>
        <div className='main_nav_item'>
          <Link to='/spareParts'>Запчасти</Link>
        </div>
        <div className='main_nav_item'>
          <Link to='/toys'>Игрушки и интерьер</Link>
        </div>
        <div className='main_nav_item'>
          <Link to='/service'>Cервис</Link>
        </div>
        <div className='main_nav_item main_nav_3dservice'>
          <Link to='/3dservice'>3D Услуги</Link>
          <ul className='root_item double_submenu dropdown_3dservice'>
            <li><Link to='/printing'>Печать</Link></li>
            <li><Link to='/scanning'>Сканирование</Link></li>
            <li><Link to='/modeling'>Моделирование</Link></li>
          </ul>
        </div>
        <div className='main_nav_item'>
          <Link to='/tradeIn'>Trade In</Link>
        </div>
        <GetCall />
      </nav>
    </div>
  )
}