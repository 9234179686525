import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import ApiPhoto from '../ApiPhoto';
import ApiFilamentSpecifications from '../ApiFilamentSpecifications';
import AboutList from '../AboutList';
import { Helmet } from 'react-helmet-async';


export default function SoloFilamentPage(props) {
  const [items, setItems] = useState([]);
  const {productId} = useParams();
  const [productVariationId, setProductVartiationId] = useState(productId)
  const variationClick = (value) => {
    setProductVartiationId(value)
  }
    useEffect(() => {
      window.scrollTo({
        top: 100,
        left: 0,
        behavior: 'smooth'
      });
      fetch(`https://3dsyndicate-pereklichka.ru/api/filamentUnion/${productId - 2}`)
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
      )
    }, [props, productId]);    
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Филамент для 3D принтера. Купить пластик PLA, PetG, ABS, Nyloon, Carbon. Производители Creality, Kingroon, U3Print, ABSMaker, Anycubic. Санкт-Петербург. FDM</title>
        <meta  name='description' content='Филамент для 3D принтера в Санкт-Петербурге. Пластик, Юр.Лица.'/>
        <meta name="keywords" content="Пластик, филамент, Fdm, petg, Creality" />
      </Helmet>
    <Header />
    <Nav />
    <main className='product_page_main'>
    {items.filament && (
      <>
      <ApiPhoto items={items.photo} productId={`${productVariationId}`} />
      <ApiFilamentSpecifications variationClick={variationClick} variationId={productVariationId} items={items} />
      </>)}
    </main>
    <AboutList />
    <SocialBlock />
    <YandexMap />
    <Footer />
  </React.Fragment>
  )
}