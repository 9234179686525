import React from 'react'
import photo1 from '../img/3dservie/equipment/photo1.jpg'
import photo2 from '../img/3dservie/equipment/photo2.jpg'
import photo3 from '../img/3dservie/equipment/photo3.jpg'
import photo4 from '../img/3dservie/equipment/photo4.jpg'
import photo5 from '../img/3dservie/equipment/photo5.jpg'
import photo6 from '../img/3dservie/equipment/photo6.jpg'

export default function EquipmentService() {
  return (
    <div className='galary_service'>
        <div className='galary_service_title'>Наше оборудование</div>
        <div className='galary_service_photo'>
            <img className='galary_service_photo_item' src={photo1} alt='Бамбу лаб с системой амс и жаба напечатанная на нем' />
            <img className='galary_service_photo_item' src={photo2} alt='3D печать игрушки дракона на 3D принетер Crelaity K1' />
            <img className='galary_service_photo_item' src={photo3} alt='Ферма 3D принетров на Creality k1' />
            <img className='galary_service_photo_item' src={photo4} alt='3D печать соединителей рам москитной сетки' />
            <img className='galary_service_photo_item' src={photo5} alt='3D печать воронок для ADBLUE красный Creality k1c' />
            <img className='galary_service_photo_item' src={photo6} alt='Сервис 3D принетов и печать черепа на Creality k1 max' />
        </div> 
    </div>
  )
}
