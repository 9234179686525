import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import headImg from '../../img/3dservie/service2.png'
import GetCall from '../GetCall';
import EquipmentService from '../EquipmentService';
import SliderService from '../SliderService';
import ServiceNav from '../ServiceNav';
import { Helmet } from 'react-helmet-async';

export default function ServiceModelingPage() {
  return (
    <React.Fragment>
        <Helmet>
        <meta charSet='utf-8' />
        <title>Моделирование на заказ. Технические и художественные модели для 3D печати.</title>
        <meta  name='description' content='3D Моделирование для печати на 3D принтере. Художественное моделирование. Реверс инжеринг модели.'/>
        <meta name="keywords" content="моделирование, реверс инжеринг, для 3д печати, сканирование" />
      </Helmet>
    <Header />
    <Nav />
    <main className='three_service_main'>
      <div className='modeling_head'>
        <div className='modeling_head_text'>
          <div className='modeling_head_text_title'>3D Моделирование</div>
          <h4 className='modeling_head_text_h2'>Что мы моделируем?</h4>
          <p className='modeling_head_text_p'>Мы занимаемся разработкой моделей для различных задач, 
          в основном - для дальнейшей печати. 
          Технические детали, элементы интерьера, игрушки, и многое другое.</p>

          <h4 className='modeling_head_text_h2'>Почему стоит выбрать нас:</h4>
          <p className='modeling_head_text_p'> 
           • Разрабатываем как технические, так и творческие модели.<br/>
           • Согласовываем каждый этапа работы для достижения лучшего результата.<br/>
           • Можем воссоздавать утраченные детали и проектировать
          модели под конкретные задачи.</p>

          <h4 className='modeling_head_text_h2'>Как заказать 3D моделирование:</h4>
          <p className='modeling_head_text_p'>
          1) Подробно описать свою идею.<br/>
          2) Предоставить примеры работ или фото/эскизы/чертежи.<br/>
          3) Связаться с нами в удобном для вас мессенджере (WhatsApp, Telegram) 
          для уточнения деталей, стоимости и сроков работы.</p>
          <h4 className='modeling_head_text_h2'>Обратите внимание, что минимальная стоимость печати от 3 000 рублей.</h4>
          <GetCall />          
        </div>
        <div className='modeling_head_img'><img src={headImg} alt='Моделирование кубка для турнира' />
          <div className='modeling_head_img_alt'>Часть модели сборного стилизированного кубка</div>
        </div>
      </div>
      <p className='service_galary_title'>Галерея наших работ</p> 
      <SliderService />
      <EquipmentService />
      <ServiceNav />
      <SocialBlock />
    </main>
    <YandexMap />
    <Footer />
  </React.Fragment>
  )
}
