import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import YandexMap from '../YandexMap';
import SocialBlock from '../SocialBlock';
import ApiPhoto from '../ApiPhoto';
import ApiToysSpecifications from '../ApiToysSpecifications';
import AboutList from '../AboutList';
import { Helmet } from 'react-helmet-async';

export default function SoloToysPage() {
    const [items, setItems] = useState([]);
    const {productId} = useParams();
    const [productVariationId, setProductVartiationId] = useState(productId)
    const variationClick = (value) => {
      setProductVartiationId(value)
    }
      useEffect(() => {
        window.scrollTo({
          top: 100,
          left: 0,
          behavior: 'smooth'
        });
        fetch(`https://3dsyndicate-pereklichka.ru/api/toysUnion/${productId - 2}`,)
        .then(res => res.json())
        .then(
          (result) => {
            setItems(result);
          },
        )
      }, [productId]);    
    return (
      <React.Fragment>
      <Header />
      <Helmet>
        <meta charSet='utf-8' />
        <title>Игрушки, товары для дома и автомобиля напечатанные на 3D принетре. Продажа игрушек оптом. Игрушки напечатанные на 3D принтере для дома и магазина. Поставщик 3D Игрушек. Работа с Юр. Лицами</title>
        <meta  name='description' content='Игрушки, товары для дома и интерьера. Купить оптом в Сантк-Петербурге. Детские игрушки.'/>
        <meta name="keywords" content="игрушки, на 3д принетре, 3д печать, купить, запчасти, воронки, соеднители для москитной сетки" />
      </Helmet>
      <Nav />
      <main className='product_page_main'>
      {items.toys && (
        <>
        <ApiPhoto items={items.photo} productId={`${productVariationId}`} />
        <ApiToysSpecifications variationClick={variationClick} variationId={productVariationId} items={items}/>
        </>)}
      </main>
      <AboutList />
      <SocialBlock />
      <YandexMap />
      <Footer />
    </React.Fragment>
    )
  }
  