import React from 'react'
import { useEffect, useState } from 'react';
import {  useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


export default function ApiProducts(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [link, setLink] = useState(`https://3dsyndicate-pereklichka.ru/api/${props.apiRequest}?`);
  const actualParams = useSelector((state) => state.paramsLink.params);
 

  function nextLink(value){
    if(value === null){
      if(window.screen.width < 700 )
      window.scrollTo({
        top: 350,
        left: 0,
        behavior: 'smooth'
      })
      else{
        window.scrollTo({
          top: 70,
          left: 0,
          behavior: 'smooth'
        })
      }
      return `https://3dsyndicate-pereklichka.ru/api/${props.apiRequest}?`;
    } else {
      if(window.screen.width < 700 )
        window.scrollTo({
          top: 350,
          left: 0,
          behavior: 'smooth'
        })
        else{
          window.scrollTo({
            top: 70,
            left: 0,
            behavior: 'smooth'
          })
        }
      return value
    }
  }
  

  function editLabel(value){
    if(value === '&laquo; Previous'){
      return "Предыдущая";
    }else if(value === 'Next &raquo;'){
      return "Следующая";
    }else{ 
      return value;
    }
  }

  useEffect(() => {
    fetch(link + actualParams, )
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
            setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [props.apiRequest, link, actualParams])

  if (error) {
    return <div>Ошибка: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Загрузка...</div>;
  } else { 
    return (
      <div className='product_catalog'>
        <div className='product_catalog_block'>
        {items.data.map(item => (
          <Link key={item.id} className={'product_item'}   to={`/${props.apiRequest}/${item.bitrix_product_id}`}>
            <div className='product_catalog_div'><img className='product_catalog_photo' alt='Товарное фото для каталога' src={`${item.main_photo}`}/></div>
              <div className='product_catalog_name'>{item.name}</div>
              <div className='product_catalog_price_status'>
                <div className='product_catalog_price'>{item.price} руб.</div>
                <div className='product_catalog_status'>{item.product_status}</div>
              </div>
              <div className='product_catalog_typePrinter'>Тип {props.typeProductName}: {item[props.typeProduct]}</div>
          </Link>
        ))}
        </div>
        <div className='pages_slider_block'>
        {items.links.map(item =>(
          <div key={item.label} onClick={() => setLink(nextLink(item.url))} className={`pages_slider_item ${item.active}`}>
            <span className={`pages_slider_item_span`}>{editLabel(item.label)}</span>
          </div>
        ))}
        </div>
      </div>
    );
  }
}