import React from 'react'
import yandex_black_img from '../img/marketplaces/yandex_3.png'
import wb_black_img from '../img/marketplaces/wb.png'
import ozon_black_img from '../img/marketplaces/ozon_96.png'

export default function MarketplacesBlack() {
  return (
    <>
        <div><a href='https://www.ozon.ru/seller/3d-studiya-sindikat-1197788/products/?miniapp=seller_1197788' target='_blank' rel="noopener noreferrer"><img height={'38px'} src={ozon_black_img} alt='ССыллка на наши товары на озоне' /></a></div>
        <div><a href='https://www.wildberries.ru/seller/4139501' target='_blank' rel="noopener noreferrer"><img style={{marginTop: '1px'}} height={'32x'} src={wb_black_img} alt='ССыллка на наши товары на вайлдберриз' /></a></div>
        <div><a href='https://market.yandex.ru/business--studiia-3d/80665632' target='_blank' rel="noopener noreferrer"><img height={'32px'} src={yandex_black_img} alt='ССыллка на наши товары на яндекс маркете' /></a></div>
    </>
  )
}
