import React from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";

export default function MessangerIcons() {
  return (
    <div className='icons_messanger'>
        <FaWhatsapp className="icons_whatsapp" onClick={() => window.open('https://wa.me/+79110276851', '_blank')}></FaWhatsapp>
        <FaTelegram className="icons_telegram" onClick={() => window.open('https://t.me/studio3dspb', '_blank')}></FaTelegram>
  </div>
  )
}
