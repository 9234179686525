import React from 'react'
import { useState } from 'react'
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterHousingPrinter() {
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_housingPrinter'>
    <div className='filter_second_title housingPrinter_title' onClick={()=>setHidden(!hidden)}>Тип корпуса <FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
   {!hidden &&(<>
      <label className='label_filter open'>
        <input type='checkbox' className='option_housingPrinter' name='option_housing' onClick={() => dispatch(changeParams('in[housingPrinter][]=Открытый&'))} value={'open'}/>
        <span>Открытый</span>
      </label>
      <label className='label_filter closed'>
        <input type='checkbox' className='option_housingPrinter' name='option_housing' onClick={() => dispatch(changeParams('in[housingPrinter][]=Закрытый&'))} value={'closed'}/>
        <span>Закрытый</span>
      </label>
    </>)}
</div>
  )
}
