import React from 'react';
import Header from '../Header';
import Nav from '../Nav';
import Footer from '../Footer';
import { GiTrade } from "react-icons/gi";
import { MdQuestionMark } from "react-icons/md";
import { TbPointFilled } from "react-icons/tb";
import { MdPriceCheck } from "react-icons/md";
import { LuArrowUpWideNarrow } from "react-icons/lu";
import { FaRoadCircleCheck } from "react-icons/fa6";
import SocialBlock from '../SocialBlock';
import YandexMap from '../YandexMap';
import AboutList from '../AboutList';
import headimg from '../../img/tradeIn/head.png'
import step1 from '../../img/tradeIn/step1.png'
import step2 from '../../img/tradeIn/step2.png'
import step3 from '../../img/tradeIn/step3.png'
import step4 from '../../img/tradeIn/step4.png'
import { Helmet } from 'react-helmet-async';



export default function TradeInPage() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Продать 3D принтер в Санкт-Петербурге. Trade In. Сдать старый принтер в обмен на новый. Работа с Юр. Лцами.</title>
        <meta  name='description' content='Обмен старого принтера на новый по системе Trade In. Продать старый принетр в Санкт-Петербурге и купить новый.'/>
        <meta name="keywords" content="обмен, купить, продать 3д принтер, старый" />
      </Helmet>
    <Header />
    <Nav />
    <main className='tradeIt_page'>
    <img className='tradeInHeadImg' src={headimg} alt='Trade in обмен'></img>
    <h2><GiTrade />Программа Trade In</h2>
      <div className='tradeIn_head_text'>
      <h3><MdQuestionMark />Как работает программа Trade In</h3> 
            Обмен 3D принтера на новый с доплатой или без неё.
            Размер доплаты зависит от диагностики старого 3D принтера. Наши специалисты
            определяют точную сумму после оценки ключевых факторов:<br/><br/>
            <div><TbPointFilled /> Состояние 3D принтера</div>
            <div><TbPointFilled /> Год выпуска</div>
            <div><TbPointFilled /> Оценка рыночной стоимости</div>
      </div>
      <div className='tradeIn_way'>
        <div className='tradeIn_way_item'>
          <img src={step1} alt='Иконка осмотр и оценка'/>
          <h2>1.</h2>
          <h3>Осмотр и оценка</h3>
          <p>Вашего 3D принтера</p>
        </div>
        <div className='tradeIn_way_item'>
          <img src={step2} alt='Иконка выбора 3D принтера'/>
          <h2>2.</h2>
          <h3>Выбор</h3>
          <p>нового 3D принтера</p>
        </div> 
        <div className='tradeIn_way_item'>
          <img src={step3} alt='Иконка оформление документов'/>
          <h2>3.</h2>
          <h3>Оформлене документов</h3>
          <p>и просчет финансов</p>
        </div> 
        <div className='tradeIn_way_item'>
          <img src={step4} alt='Иконка новый 3D принтер'/>
          <h2>4.</h2>
          <h3>Новый 3D принтер</h3>
          <p>теперь Ваш</p>
        </div>  
      </div>
      <div className='tradeIn_main_text'>
      <h3><MdPriceCheck />По каким критериям оценивается 3D принтер?</h3>
        <div className='tradeIn_main_text_item'>
            3D принтер с пробегом оценивается по множеству показателей, вместе определяющих его итоговую стоимость. К наиболее значимым из них относятся следующие критерии:<br/><br/>
            1. Техническое состояние. Кроме геометрии рамы, немаловажное значение имеет состояние стола, печатающей головы, кинематики  и других сложных компонентов. 3D принтер, требующий ремонта или имеющий существенные дефекты по технической части, всегда стоит дешевле 3D принтера с наименьшим количеством дефектов.<br/><br/>
            2. Год выпуска. Как правило, чем новее 3D принтер, тем меньше он изношен и тем меньше денег требуется на его ремонт. Однако важно понимать, что это преимущество может перекрываться другими существенными недостатками, как, например, фактом «колхозного тюнинга».<br/><br/>
            3. Пробег. В том случае если его позволяет определить принтер. Он сообщает о том, насколько интенсивно эксплуатируется 3D принтер. Малый пробег — это серьезный повод повысить цену относительно других предложений в рынке.<br/><br/>
            4. Комплектация. Порой одна и та же модель бывает доступна в нескольких вариантах, сильно отличающихся между собой.<br/><br/>
            5. Ликвидность на вторичном рынке. Даже технически исправный 3D принтер в хорошим состоянии  может продаваться месяцами, если на него нет спроса. Чем менее востребован 3D принтера на вторичном рынке, тем негативнее влияет этот фактор на оценочную стоимость.<br/><br/>
          </div>
        <h3><LuArrowUpWideNarrow />Какие преимущества дает покупка в «Трейд-Ин»?</h3>
        <div className='tradeIn_main_text_item'>
            1. Не нужно тратить время и деньги. При организации самостоятельного обмена, клиент сталкивается с рядом трудностей. Сначала необходимо продать свой 3D принтер, что несет дополнительные затраты средств на подготовку, трата личного времени на встречи с потенциальными клиентами. Поиск подходящего нового 3D принтера.<br/><br/>
            3. Возможность купить новый 3D принтер в кредит. В этом случае зачет стоимости старого 3D принтера может полностью или частично стать первым взносом по кредитному договору на новый 3D принтер. Подача заявки на кредит и оформление осуществляется в нашем центре.<br/><br/>
            4. Богатые возможности по части обмена. 3D принтер можно приобрести за наличные или в кредит. При этом доплата возможна как со стороны покупателя, так и со стороны центра.<br/><br/>
          </div>
          <h3><FaRoadCircleCheck />Как проходит «Трейд-Ин»?</h3>
          <div className='tradeIn_main_text_item'>Сначала наши специалисты делают предварительную оценку сдаваемого 3D принтера, в результате которой станет известна его ориентировочная цена. <br/>Далее проводится тщательная диагностика 3D принтера, по результатам которой составляется протокол с информацией о его техническом состоянии и, соответственно, сообщается точная стоимость. <br/>Затем следует заключение договора купли-продажи, доплата разницы в цене с той или иной стороны.</div>
      </div>
    </main>
    <AboutList />
    <SocialBlock />
    <YandexMap />
    <Footer />
  </React.Fragment>
  )
}
