import React, { useState } from 'react'
import Button from './Button';
import Input from './Input';


export default function GetCall() {

  let [getCallOpen, setGetCallOpen] = useState(false);

  return (
    <React.Fragment>
      <div onClick={() => setGetCallOpen(getCallOpen = !getCallOpen)} className={`get_call ${getCallOpen && 'active' }`}>Запросить звонок!</div>

      {getCallOpen &&(<div className='pop_up active' >
          <div className="pop_up_container">
              <div className="pop_up_body">
                <div onClick={() => setGetCallOpen(getCallOpen = !getCallOpen)} className="pop_up_close">&#x2716;</div>
                <div className='pop_up_logo'></div>
                <div className='pop_up_content'>
                  <h3>Заказать звонок</h3>
                  <form action='#' id='get_call_form' className='get_call_form'>
                    <div className='get_call_input'>
                      <Input text='Имя' id='name' name='name'/>
                    </div>
                    <div className='get_call_input'>
                      <Input text='Телефон' id='phone' name='phone'/>
                    </div>
                    <div id='status_reqest_success' >Заявка успешно отправлена!</div>
                    <div id='status_reqest_fold' >Данные введены неверно!</div>
                    <Button />
                  </form>
                </div>
              </div>
          </div>
      </div>)}
    </React.Fragment> 
  )
}
