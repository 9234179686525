import React, { useEffect, useState } from 'react'
import FilterPrice from './FilterPrice';
import StatusFilter from './StatusFilter';
import SideBar from './SideBar';
import FilterHead from './FilterHead';
import FilterFilamentType from './FilterFilamentType';
import { IoIosArrowDown } from 'react-icons/io';


export default function FilterFilament() {
  const [hidden, setHidden] = useState(false);
  
  useEffect(()=>{
    if(window.innerWidth < 700){
      setHidden(true)
    }
  },[])
  return (
    <div className='sidebar'>
    <SideBar />
    <div className='filter_main'>
            <FilterHead />
            <div><IoIosArrowDown className={`hide_filter ${!hidden}`} onClick={()=>setHidden(!hidden)}/></div>
            {!hidden &&(<>
            <FilterPrice />
            <StatusFilter />
            <FilterFilamentType /></>)}
        </div>
</div>
  )
}
