import React, { useEffect, useRef } from 'react'
import photo1 from '../img/3dservie/slider/1.png'
import photo2 from '../img/3dservie/slider/2.png'
import photo3 from '../img/3dservie/slider/3.jpg'
import photo4 from '../img/3dservie/slider/4.jpg'
import photo5 from '../img/3dservie/slider/5.png'
import photo6 from '../img/3dservie/slider/6.png'
import photo7 from '../img/3dservie/slider/7.png'
import photo8 from '../img/3dservie/slider/8.png'

import { register } from 'swiper/element'

export default function SliderService() {
  const swiperRef = useRef(null);

  useEffect(() => {
    // Register Swiper web component
    register();

    // Object with parameters
    const params = {
      slidesPerView: 1,
      autoplay: {
        delay: 4000,
      },
      speed: 1500,
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);
  return (
    <div className='swiper_service'>
        <swiper-container  style={{
            "--swiper-navigation-color": "#fff", 
            "--swiper-pagination-color": "#D5001C",
            "--swiper-pagination-bullet-size": "5px",
            "--swiper-navigation-size": "30px",
            }}
             navigation="true" init="false" ref={swiperRef}>
                <swiper-slide><img alt='Фото примера наших работ, моделирование в блендере кубок' src={photo1}/><img alt='Фото примера наших работ, моделирование в блендере эндермен' src={photo2}/></swiper-slide>
                <swiper-slide><img alt='Фото примера наших работ, печать кубков для турнира серебро' src={photo3}/><img alt='Фото примера наших работ, печать кубков для турнира золото' src={photo4}/></swiper-slide>
                <swiper-slide><img alt='работа во фюжене воронка' src={photo5}/><img alt='работа во фюжене крепление для неона' src={photo6}/></swiper-slide>
                <swiper-slide><img alt='работа во фюжене моделирование лося' src={photo7}/><img alt='воронка для wag машин' src={photo8}/></swiper-slide>
        </swiper-container>
    </div>
  )
}
