import React, { useState } from 'react'
import wb_img from '../img/marketplaces/wb.png';
import ozon_img from '../img/marketplaces/ozon_96.png';
import yandex_img from '../img/marketplaces/yandex_3.png';
import GetCall from './GetCall';
import MessangerIcons from './MessangerIcons';


export default function ApiSparePartsSpecifications(props) {
    const [productPrice, setProductPrice] = useState(props.items.variations[0].price);
    const [productStatus, setProductStatus] = useState(props.items.variations[0].product_status)
    const spareParts = props.items.spareParts[0];
    const property = props.items.property;
    const variations = props.items.variations;
    function isValue(val){
      return (val !== undefined && val !== null && val !== '' && val !== "0");
  }
      return (
        <>
        <div className='product_specifications'>
            <div className='product_specifications_name'>{spareParts.name}</div>
            <div className='product_specifications_block'>
              <div className='product_specifications_name_block'>
                <div className='product_specifications_item_name'>Производитель </div>
                <div className='product_specifications_item_name'>Тип запчасти </div>
                <div className='product_specifications_item_name'>Для принтеров</div>
              </div>
              <div className='product_specifications_defis_block'>
                <div className='product_specifications_item_defis'>—</div>
                <div className='product_specifications_item_defis'>—</div>
                <div className='product_specifications_item_defis'>—</div>
              </div>
              <div className='product_specifications_value_block'>
                <div className='product_specifications_item_value'>{spareParts.manufacturer}</div>
                <div className='product_specifications_item_value'>{spareParts.typeSpare}</div>
                <div className='product_specifications_forPrinter'>
                  {property.map(item => (
                      <div key={item.id}>{item.value}</div>
                  ))}
                  </div>
              </div>
              <div className='product_specifications_buy_block'>
                <div className='product_specifications_price'>{productPrice}<span style={{fontSize : "18px"}}>₽/шт</span></div>
                <div className='product_specifications_status'>{productStatus}</div>
                <GetCall />
                <span className='product_specification_write_messanger'>Написать нам в мессенджере:</span>
                <MessangerIcons />
              </div>
            </div>
            {variations[1] && (<>
            <span>Вариации:</span>
            <div className='product_variation_block'>
              {variations.map(item => (
                <div className="product_variation_subBlock" key={item.id}>
                  {isValue(variations[0].property_167) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_167}</div>)}
                  {isValue(variations[0].property_171) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_171}</div>)}
                  {isValue(variations[0].property_173) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_173}</div>)}
                  {isValue(variations[0].property_169) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_169}</div>)}
                  {isValue(variations[0].property_175) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_175}</div>)}
                  {isValue(variations[0].property_177) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_177}</div>)}
                  {isValue(variations[0].property_289) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_289}</div>)}
                  {isValue(variations[0].property_291) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_291}</div>)}
                  {isValue(variations[0].property_293) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_293}</div>)}
                  {isValue(variations[0].property_295) && ( <div onClick={()=>{props.variationClick(item.id); setProductPrice(item.price); setProductStatus(item.product_status)}} className='product_variation_item'>{item.property_295}</div>)}
                </div>
              ))}
            </div></>)}
            {spareParts.ozon_url !== '' && (<>
            <span style={{fontSize:"14px", fontWeight: "400" }}>Товар на маркетплейсах:</span>
            <div className='product_specification_mp_icons'>
              <div className='product_specifications_ozon_url product_specifications_url'><a href={spareParts.ozon_url} target='_blank' rel="noopener noreferrer"><img height={'48px'} src={ozon_img} alt='Ссылка на данный товар на озоне' /> </a></div>
              <div className='product_specifications_wb_url product_specifications_url'><a href={spareParts.wb_url} target='_blank' rel="noopener noreferrer"><img height={'40x'} src={wb_img} alt='Ссылка на данный товар на вайлдберриз' /> </a></div>
              <div className='product_specifications_yandex_url product_specifications_url'><a href={spareParts.yandex_url} target='_blank' rel="noopener noreferrer"><img height={'40px'} src={yandex_img} alt='Ссылка на данный товар на яндекс маркете' /> </a></div>
            </div></>)}
        </div>
        </>
      );
    }