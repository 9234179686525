import React from 'react'
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterManufacturer() {
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_manufacturer'>
        <div className='manufacturer_title filter_second_title' onClick={()=>setHidden(!hidden)}>Производитель <FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
        {!hidden && (<>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_manufacturer'  onClick={() => dispatch(changeParams('in[manufacturer][]=Creality&'))} />
          <span>Creality</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_manufacturer' onClick={() => dispatch(changeParams('in[manufacturer][]=Anycubic&'))} />
          <span>Anycubic</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_manufacturer' onClick={() => dispatch(changeParams('in[manufacturer][]=Bambu Lab&'))} />
          <span>Bambu Lab</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_manufacturer' onClick={() => dispatch(changeParams('in[manufacturer][]=FlyingBear&'))} />
          <span>FlyingBear</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_manufacturer' onClick={() => dispatch(changeParams('in[manufacturer][]=Kingroon&'))} />
          <span>Kingroon</span>
        </label>
        </>)}
    </div>
  )
}
