import { createSlice } from "@reduxjs/toolkit";

export const paramsLinkSlice = createSlice({
    name: 'paramsLink',
    initialState: {
        params: '',
        page: ''
    },
    reducers:{
        changeParams: (state, value) => {

            if(value.payload.slice(4,9) === 'price'){
                if(state.params.indexOf(value.payload.slice(0,10)) === -1){
                    state.params += value.payload;
                 }else{
                    state.params = state.params.replace(state.params.slice(state.params.indexOf(value.payload.slice(0,10)), state.params.indexOf('&', state.params.indexOf(value.payload.slice(0, 10))) + 1), value.payload);
                 }
            
            }else if(value.payload.slice(9,21) === 'typeFilament'){
                if(state.params.indexOf(value.payload.slice(9,21)) === -1){
                    state.params += value.payload;
                 }else if(state.params.indexOf(value.payload.slice(9,-1)) >= 0){
                    state.params = state.params.replace(value.payload, '');
                 }else if(state.params.indexOf(value.payload.slice(9,21)) >= 0 || state.params.indexOf(value.payload.slice(9,-1)) === -1){
                    state.params = state.params.replace(state.params.slice(state.params.indexOf(value.payload.slice(0,10)), state.params.indexOf('&', state.params.indexOf(value.payload.slice(0, 10))) + 1), value.payload);
                 }
            }else{
                if(state.params.indexOf(value.payload) === -1){
                    state.params += value.payload
                   }else{
                    state.params = state.params.replace(value.payload, '');
                   }
            }
        },

        changePage: (state, value) =>{
            state.page = value.payload
        },

        resetParams: (state) => {
            state.params = '';
            Array.from(document.getElementsByClassName('option')).forEach(e1 =>{
                e1.checked = false
            })
        },
    },
})

export const {changeParams, resetParams, changePage} = paramsLinkSlice.actions

export default paramsLinkSlice.reducer