import React from 'react'
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterTypeToys() {
    const [hidden, setHidden] = useState(false);
    const dispatch = useDispatch();

    return (
      <div className='filter_sidebar filter_typePrinter'>
      <div className='typeToys_title filter_second_title' onClick={()=>setHidden(!hidden)}>Тип<FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
      {!hidden && (<>
      <label className='label_filter'>
        <input type='checkbox' className='option' name='otion_typeToys' onClick={() => dispatch(changeParams('in[typeToys][]=Игрушка&'))} />
        <span>Игрушка</span>
      </label>
      <label className='label_filter'>
        <input type='checkbox' className='option' name='otion_typeToys' onClick={() => dispatch(changeParams('in[typeToys][]=Товары для дома&'))} />
        <span>Товары для дома</span>
      </label>
      <label className='label_filter'>
        <input type='checkbox' className='option' name='otion_typeToys' onClick={() => dispatch(changeParams('in[typeToys][]=Товары для автомобиля&'))} />
        <span>Товары для автомобиля</span>
      </label>
      <label className='label_filter'>
        <input type='checkbox' className='option' name='otion_typeToys' onClick={() => dispatch(changeParams('in[typeToys][]=Интструмент&'))} />
        <span>Инструмент</span>
      </label>
      </>)}
  </div>
  )
}
