import MainPage from "./components/pages/MainPage";
import FilamentPage from "./components/pages/FilamentPage";
import PrintersPage from "./components/pages/PrintersPage";
import ServiceModelingPage from "./components/pages/ServiceModelingPage";
import ServicePage from "./components/pages/ServicePage";
import ServicePrintingPage from "./components/pages/ServicePrintingPage";
import ServiceScanningPage from "./components/pages/ServiceScanningPage";
import ThreeDServicePage from "./components/pages/ThreeDServicePage";
import TradeInPage from "./components/pages/TradeInPage";
import SparePartsPage from "./components/pages/SparePartsPage";
import ToysPage from "./components/pages/ToysPage";
import SoloProductPage from "./components/pages/SoloProductPage";
import SoloSparePartsPage from "./components/pages/SoloSparePartsPage";
import SoloFilamentPage from "./components/pages/SoloFilamentPage";
import SoloToysPage from "./components/pages/SoloToysPage";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./components/pages/NotFound";

function App(props) {
  return (
    <HelmetProvider >
    <div className="wrapper">
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path={"/main/:type"} element={<MainPage />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/filament" element={<FilamentPage />} />
          <Route path="/printers" element={<PrintersPage />}  />
          <Route path="/modeling" element={<ServiceModelingPage />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/printing" element={<ServicePrintingPage />} />
          <Route path="/scanning" element={<ServiceScanningPage />} />
          <Route path="/spareParts" element={<SparePartsPage />} />
          <Route path="/3dservice" element={<ThreeDServicePage />} />
          <Route path="/tradeIn" element={<TradeInPage />} />
          <Route path="/toys" element={<ToysPage />} />
          <Route path={"/productsPrinters/:productId"} element={<SoloProductPage />} />
          <Route path={"/productsSpareParts/:productId"} element={<SoloSparePartsPage />} />
          <Route path={"/productsFilament/:productId"} element={<SoloFilamentPage />} />
          <Route path={'/productsToys/:productId'} element={<SoloToysPage />} />
          <Route path="*" element={<NotFound />} />
         </Routes>
      </Router>
    </div>
    </HelmetProvider>
  );
}

export default App;
