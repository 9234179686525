import React, { useState } from 'react'
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterTypeSpareParts() {
    const [hidden, setHidden] = useState(false);
    const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_typePrinter'>
        <div className='typePrinter_title filter_second_title' onClick={()=>setHidden(!hidden)}>Тип запчасти<FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
        {!hidden && (<>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=HotEnd&'))} />
          <span>HotEnd</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Экструдер&'))} />
          <span>Экструдер</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Фидер&'))} />
          <span>Фидер</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Сопло&'))} />
          <span>Сопло</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Горло&'))} />
          <span>Горло</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Нагреватель&'))} />
          <span>Нагреватель</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Термистор&'))} />
          <span>Термистор</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Стол&'))} />
          <span>Стол</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Радиатор&'))} />
          <span>Радиатор</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Термоблок&'))} />
          <span>Термоблок</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=HotEnd с радиатором&'))}/>
          <span>HotEnd с радиатором</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Вентилятор&'))} />
          <span>Вентилятор</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Клей для 3D печати&'))}/>
          <span>Клей для 3D печати</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Камера&'))} />
          <span>Камера</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Плата&'))}/>
          <span>Плата</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Сликоновая насадка&'))}/>
          <span>Сликоновая насадка</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Шестерня&'))}/>
          <span>Шестерня</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Шлейф&'))}/>
          <span>Шлейф</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Дверца&'))}/>
          <span>Дверца</span>
        </label>
        <label className='label_filter'>
          <input type='checkbox' className='option' name='option_typeSpare' onClick={() => dispatch(changeParams('in[typeSpare][]=Крышка&'))} />
          <span>Крышка</span>
        </label>
        </>)}
    </div>
  )
}
