import React from 'react'
import { useState } from 'react';
import { FaAngleRight } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { changeParams } from '../features/paramsLinkSlice';


export default function FilterTypePrinter (){
    const [hidden, setHidden] = useState(false);
    const dispatch = useDispatch();

  return (
    <div className='filter_sidebar filter_typePrinter'>
    <div className='typePrinter_title filter_second_title' onClick={()=>setHidden(!hidden)}>Технология печати<FaAngleRight className={`filter_second_title_icon ${!hidden}`}/></div>
    {!hidden && (<>
    <label className='label_filter'>
      <input type='checkbox' className='option' name='option_typePrinter' onClick={() => dispatch(changeParams('in[typePrinter][]=FDM&'))} />
      <span>FDM</span>
    </label>
    <label className='label_filter'>
      <input type='checkbox' className='option' name='option_typePrinter' onClick={() => dispatch(changeParams('in[typePrinter][]=LCD&'))} />
      <span>LCD</span>
    </label>
    <label className='label_filter'>
      <input type='checkbox' className='option' name='option_typePrinter' onClick={() => dispatch(changeParams('in[typePrinter][]=Сушилка&'))} />
      <span>Сушилка</span>
    </label>
    <label className='label_filter'>
      <input type='checkbox' className='option' name='option_typePrinter' onClick={() => dispatch(changeParams('in[typePrinter][]=Мойка&'))} />
      <span>Мойка</span>
    </label>
    </>)}
</div>
  )
}
